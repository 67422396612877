import React, {useState} from "react"
import {useUser} from "@principal-aws-oidc-auth/react"
import CompetitorPricingResult from './CompetitorPricingResult'
import loading from '../resources/loading.gif'

export default function CompetitorPricing(){
    const [hardCopyProposal, setHardCopyProposal] = useState()
    const [competitorModel, setCompetitorModel] = useState()
    const [discounts, setDiscounts] = useState()
    const [proprietaryFixedCode, setProprietaryFixedCode] = useState(false)
    const [targetDateFundCode, setTargetDateFundCode] = useState(false)
    const [optInMaCode, setOptInMaCode] = useState(false)
    const [optOutMaCode, setOptOutMaCode] = useState(false)
    const [dynamicQDIACode, setDynamicQDIACode] = useState(false)
    const [passiveIndexCode, setPassiveIndexCode] = useState(false)
    const [activeIndexCode, setActiveIndexCode] = useState(false)
    const [transferAssets, setTransferAssets] = useState()
    const [annualDeposits, setAnnualDeposits] = useState()
    const [ppts, setPpts] = useState()
    const user = useUser()
    const [result, setResult] = useState()
    const [originalInputs, setOriginalInputs] = useState()
    const [loadingGif, setLoadingGif] = useState(false)

    const handleDiscountsChange = (event) => {
        setDiscounts(event.target.value)

        // Reset checkbox states when "Open Architecture" is selected
        if (event.target.value === 'Open Architecture') {
            setProprietaryFixedCode(false)
            setTargetDateFundCode(false)
            setOptInMaCode(false)
            setOptOutMaCode(false)
            setDynamicQDIACode(false)
            setPassiveIndexCode(false)
            setActiveIndexCode(false)
        }
    };

    const selectedDiscounts = [
        { name: 'Proprietary Fixed Mandate', state: proprietaryFixedCode, setState: setProprietaryFixedCode },
        { name: 'Target Date Funds', state: targetDateFundCode, setState: setTargetDateFundCode },
        { name: 'Target Date Funds (Passive)', state: passiveIndexCode, setState: setPassiveIndexCode },
        { name: 'Target Date Funds (Index)', state: activeIndexCode, setState: setActiveIndexCode },
        { name: 'Opt-in Managed Accounts', state: optInMaCode, setState: setOptInMaCode },
        { name: 'Opt-out Managed Accounts', state: optOutMaCode, setState: setOptOutMaCode },
        { name: 'Dynamic QDIA', state: dynamicQDIACode, setState: setDynamicQDIACode },
    ]

    async function onSubmit(event){
        event.preventDefault()
        const settings={
            method: 'POST',
            headers: {'Authorization': `Bearer ${user.access_token}`},
            body: JSON.stringify({
                hardCopyProposal: hardCopyProposal,
                competitorModel: competitorModel,
                discounts: discounts,
                proprietaryFixedCode: proprietaryFixedCode,
                targetDateFundCode: targetDateFundCode,
                optInMaCode: optInMaCode,
                optOutMaCode: optOutMaCode,
                dynamicQDIACode: dynamicQDIACode,
                passiveIndexCode: passiveIndexCode,
                activeIndexCode: activeIndexCode,
                transferAssets: transferAssets,
                annualDeposits: annualDeposits,
                ppts: ppts
            })
        }
        setLoadingGif(true)

        //eda-intelligence-service-dev (671818965950):
        //const response = await fetch("https://mav1qz1pdc.execute-api.us-east-1.amazonaws.com/prod/competitor-pricing/estimatorForm", settings)

        //eda-intelligence-service-nonprd (128785858744):
        //const response = await fetch("https://p9sxxd3gl9.execute-api.us-east-1.amazonaws.com/prod/competitor-pricing/estimatorForm", settings)

        //eda-intelligence-service-prd (115855118059):
        const response = await fetch("https://28kfivdnc5.execute-api.us-east-1.amazonaws.com/prod/competitor-pricing/estimatorForm", settings)

        // to run locally:
        //const response = await fetch("http://localhost:5000/competitor-pricing/estimatorForm", settings)
        setLoadingGif(false)

        // turn json string into object
        const jsonResponse = await response.json()
        setOriginalInputs({hardCopyProposal, competitorModel, selectedDiscounts, transferAssets, annualDeposits, ppts})
        setResult(jsonResponse)
    }

    function updateNumber(event, setter){
        const formatVar = parseInt(event.target.value.replace(/\D/g, "").replace(/,/g, '')).toLocaleString()
        setter(formatVar)
    }

    return(
    <div>

    <nav className="navbar navbar-default">
        <div className="container-fluid">
          <div className="navbar-header">
            <a className="navbar-principal-brand" href="https://www.principal.com"> </a>
          </div>
            <p className="navbar-text"><strong>Competitor Pricing Estimator</strong></p>
            <ul class="nav navbar-nav navbar-right">
            <li><a href="https://competitor-intel.retirement.principal.com/" target="_blank" rel="noopener noreferrer">New CI Submission</a></li>
            </ul>
        </div>
    </nav>

    <form onSubmit={onSubmit}>
        <div className="form-group">
            <p>Hard Copy Proposal?</p>
            <select name="hardCopyProposal" className="form-control" onChange={(x)=> setHardCopyProposal(x.target.value)} value={hardCopyProposal} required>
                <option hidden disabled selected value> -- select an option -- </option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
            </select>

            <p>Competitor Model</p>
            <select name="competitorModel" className="form-control" onChange={(x)=> setCompetitorModel(x.target.value)} value={competitorModel}  required>
                <option hidden disabled selected value> -- select an option -- </option>
                <option value="Bundled">Bundled</option>
                <option value="Unbundled">Unbundled</option>
            </select>

            <p>Discounts</p>
            <select name="discounts" className="form-control" onChange={handleDiscountsChange} value={discounts}  required>
                <option hidden disabled selected value> -- select an option -- </option>
                <option value="Open Architecture">Open Architecture</option>
                <option value="Specific Discounts">Specific Discounts</option>
            </select>

             {discounts === "Specific Discounts" ?
                <div id="discounts" class="checkbox styled-checkbox">
                    <p style={{ fontStyle: 'italic' }}>For optimal performance, select no more than 2 discounts</p>
                    <input type="checkbox" id="proprietaryFixedCode" onChange={(x)=> setProprietaryFixedCode(x.target.checked)} checked={proprietaryFixedCode}/>
                    <label for="proprietaryFixedCode" style={{ marginLeft: 'auto', display: 'block' }}>Proprietary Fixed Mandate</label>

                    <input type="checkbox" id="targetDateFundCode" onChange={(x)=> setTargetDateFundCode(x.target.checked)} checked={targetDateFundCode}/>
                    <label for="targetDateFundCode" style={{ marginLeft: 'auto', display: 'block' }}>Target Date Funds</label>

                    <input type="checkbox" id="passiveIndexCode" onChange={(x)=> setPassiveIndexCode(x.target.checked)} checked={passiveIndexCode}/>
                    <label for="passiveIndexCode" style={{ marginLeft: 'auto', display: 'block' }}>Target Date Funds (Passive)</label>

                    <input type="checkbox" id="activeIndexCode" onChange={(x)=> setActiveIndexCode(x.target.checked)} checked={activeIndexCode}/>
                    <label for="activeIndexCode" style={{ marginRight: 'auto', display: 'block' }}>Target Date Funds (Index)</label>

                    <input type="checkbox" id="optInMaCode" onChange={(x)=> setOptInMaCode(x.target.checked)} checked={optInMaCode}/>
                    <label for="optInMaCode" style={{ marginLeft: 'auto', display: 'block' }}>Opt-in Managed Accounts</label>

                    <input type="checkbox" id="optOutMaCode" onChange={(x)=> setOptOutMaCode(x.target.checked)} checked={optOutMaCode}/>
                    <label for="optOutMaCode" style={{ marginLeft: 'auto', display: 'block' }}>Opt-out Managed Accounts</label>

                    <input type="checkbox" id="dynamicQDIACode" onChange={(x)=> setDynamicQDIACode(x.target.checked)} checked={dynamicQDIACode}/>
                    <label for="dynamicQDIACode" style={{ marginLeft: 'auto', display: 'block' }}>Dynamic QDIA</label>
                </div>
            :
                <div></div>
            }

            <p>Transfer Assets</p>
            <input name="transferAssets" className="form-control number" onChange={(x)=> updateNumber(x, setTransferAssets)} value={transferAssets} required/>

            <p>Annual Deposits</p>
            <input name="annualDeposits" className="form-control number" onChange={(x)=> updateNumber(x, setAnnualDeposits)} value={annualDeposits} required/>

            <p>Participants</p>
            <input name="ppts" className="form-control number" onChange={(x)=> updateNumber(x, setPpts)} value={ppts} required/>

            <input type="submit" value="Submit" className="btn btn-primary button-submit"/>
            {loadingGif &&
                <img src={loading} alt="loading prediction" className="loading-gif"/>
            }
        </div>
    </form>

    {result !== undefined
        ? <CompetitorPricingResult result={result} originalInputs={originalInputs}/>
        : null
    }

    <br></br>
    </div>

    )
}